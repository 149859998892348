var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-navigation-drawer', {
    staticClass: "the_side_nav",
    class: {
      'outage-error': _vm.outageAlertState === _vm.AlertType.ERROR,
      'outage-warning': _vm.outageAlertState === _vm.AlertType.WARNING,
      'outage-info': _vm.outageAlertState === _vm.AlertType.INFO
    },
    attrs: {
      "value": _vm.value,
      "app": "",
      "aria-orientation": "vertical",
      "disable-resize-watcher": ""
    },
    on: {
      "input": _vm.emitInput
    }
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-tile', {
    staticClass: "reporting_menu_selection",
    attrs: {
      "active-class": "",
      "exact": "",
      "to": "/reporting"
    },
    on: {
      "click": _vm.closeSideNav,
      "focus": _vm.focusEvent
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fad fa-chart-line",
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/reporting') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Reporting")])], 1)], 1), _c('v-list-tile', {
    staticClass: "hardware_search_menu_selection",
    attrs: {
      "active-class": "",
      "exact-active-class": "",
      "to": "/hardware/search"
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons.item_drawings,
      "icon-color": _vm.$route.path === '/hardware/search' ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Hardware search")])], 1)], 1), _c('v-list-tile', {
    staticClass: "hardware_search_menu_selection",
    attrs: {
      "to": "/hardware/".concat(_vm.EntityType.ASSEMBLY_TEMPLATE, "/search"),
      "active-class": "",
      "exact": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons[_vm.EntityType.ASSEMBLY_TEMPLATE],
      "icon-color": _vm.$route.path.startsWith('/hardware/assembly_templates') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Assembly Templates")])], 1)], 1)], 1), _c('v-list', [_vm._l(_vm.filteredTheSideNavigationMenu, function (item) {
    return _c('v-list-group', {
      key: item.displayName,
      ref: item.displayName.toLowerCase(),
      refInFor: true,
      attrs: {
        "prepend-icon": item.icon,
        "no-action": "",
        "dark": ""
      }
    }, [_c('v-list-tile', {
      attrs: {
        "slot": "activator",
        "active-class": "",
        "tag": "button"
      },
      slot: "activator"
    }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(item.displayName))])], 1)], 1), _vm._l(item.children, function (subItem) {
      return _c('v-list-tile', {
        key: subItem.displayName,
        attrs: {
          "to": subItem.to,
          "active-class": "",
          "exact": "",
          "exact-active-class": ""
        },
        on: {
          "click": _vm.closeSideNav
        }
      }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(subItem.displayName))])], 1), _c('v-list-tile-action', [_c('c-icon-fa', {
        staticClass: "subgroup_item_icon",
        attrs: {
          "icon": subItem.icon,
          "icon-size": "21px",
          "icon-color": _vm.$route.path.startsWith("".concat(subItem.to)) ? 'white' : "".concat(subItem.textColor)
        }
      })], 1)], 1);
    })], 2);
  }), _c('v-list-tile', {
    staticClass: "nav_item change_log",
    attrs: {
      "tag": "button"
    },
    on: {
      "click": _vm.onClickOfShowChangeLog
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fa-thin fa-wand-magic-sparkles"
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("View app updates")])], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "text-xs-center monospace_font primary white--text font-weight-bold app_version"
  }, [_vm._v("v" + _vm._s(_vm.appVersion))])], 1)], 1)], 2), _c('hr'), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "external_app_logo_tooltip"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-list-tile', _vm._g({
          staticClass: "cosmic_menu_selection",
          attrs: {
            "href": _vm.cDocsUrl
          }
        }, on), [_c('v-list-tile-action', {
          staticClass: "external_link",
          attrs: {
            "tooltip": "Navigate to ".concat(_vm.currentSystemName, " Docs")
          }
        }, [_c('img', {
          attrs: {
            "alt": "Navigate to ".concat(_vm.currentSystemName, " Docs"),
            "src": _vm.cosmicDocumentsLogoSrc,
            "height": "28"
          }
        }), _c('c-icon-fa', {
          staticClass: "ml-3",
          attrs: {
            "icon": _vm.$icons['external_link'],
            "icon-size": "12px",
            "icon-color": "black"
          }
        })], 1)], 1)];
      }
    }])
  }, [_vm._v(" Navigate to " + _vm._s(_vm.currentSystemName) + " Docs ")]), _vm.showChangeLog ? _c('ModalChangeLog', {
    on: {
      "modal-close": function modalClose($event) {
        _vm.showChangeLog = !_vm.showChangeLog;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };