function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "entity-wrapper mini_assembly_template",
    on: {
      "dblclick": function dblclick($event) {
        $event.stopPropagation();
        _vm.showModalAssemblyTemplateEdit = true;
      }
    }
  }, [_vm.hasComputedTitleOrSlot ? _c('AppHeader', {
    attrs: {
      "size": "mini",
      "uppercase": false
    }
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.computedTitle))];
  })], 2) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini no-entity-icon",
    class: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, "".concat(_vm.EntityType.ASSEMBLY_TEMPLATE, "--text text--darken-2"), _vm.isGenericAssemblyTemplate), "".concat(_vm.EntityType.EVENT, "--text"), _vm.isEventAssemblyTemplate), "".concat(_vm.EntityType.PRODUCT_BREAKDOWN_STRUCTURE, "--text"), _vm.isPbsAssemblyTemplate), "hasChildren", _vm.hasChildren), "highlight", _vm.shouldHighlightNode), "highlightBackground", _vm.shouldHighlightConnection), "hoverEffects", _vm.isAManageView), "isRoot", _vm.isRoot), "showingChildren", _vm.shouldShowChildContainer)
  }, [_c('div', {
    staticClass: "mini_chips mr-2"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.shouldOverrideSequence ? _c('v-chip', _vm._g({
          staticClass: "mini_chip seq_chip",
          attrs: {
            "disabled": ""
          }
        }, on), [_c('span', {
          staticClass: "monospace_font"
        }, [_c('strong', [_vm._v(_vm._s(_vm.sequenceOverride))])])]) : _vm._e(), !_vm.isRoot && !_vm.shouldOverrideSequence ? _c('v-chip', _vm._g({
          staticClass: "mini_chip seq_chip",
          attrs: {
            "disabled": ""
          }
        }, on), [_c('span', {
          staticClass: "monospace_font"
        }, [_c('strong', [_vm._v(_vm._s(_vm.localSequence))])])]) : _vm._e(), _vm.isRoot && !_vm.shouldOverrideSequence ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons.root,
            "icon-color": "var(--cosmic-text)"
          }
        }) : _vm._e()];
      }
    }], null, false, 3403788122)
  }, [_c('span', [_vm._v("Sequence is how " + _vm._s(_vm.currentSystemName) + " keeps the children in order.")])])], 1), _c('div', {
    staticClass: "entity-mini-name text-truncate"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1 black--text"
  }, [_vm.name !== '-' ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.name))]) : _c('i', [_vm._v("no name  ")])])]), _c('div', {
    staticClass: "mini_chips",
    class: _defineProperty(_defineProperty(_defineProperty({}, "".concat(_vm.EntityType.ASSEMBLY_TEMPLATE, "--text text--darken-2"), _vm.isGenericAssemblyTemplate), "".concat(_vm.EntityType.EVENT, "--text"), _vm.isEventAssemblyTemplate), "".concat(_vm.EntityType.PRODUCT_BREAKDOWN_STRUCTURE, "--text"), _vm.isPbsAssemblyTemplate)
  }, [_vm.shouldShowMinMaxFields ? _c('v-chip', {
    staticClass: "mini_chip min_max_chip",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("Min/Max:")]), _c('span', [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.minQuantityDisplay))]), _vm._v(" / "), _c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.maxQuantityDisplay))])])]) : _vm._e(), _vm.shouldShowPbsField ? _c('v-chip', {
    staticClass: "mini_chip pbs_id_chip",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "pr-1"
  }, [_vm._v("PBS ID:")]), _c('span', [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.localPbsItemId))])])]) : _vm._e(), _vm.shouldDisplayTrackingDocumentChip ? _c('div', {
    staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state",
    attrs: {
      "tooltip": "This is associated with a Tracking Document."
    }
  }, [_vm.computedTrackingDocumentDisplayHref ? _c('router-link', {
    attrs: {
      "to": _vm.computedTrackingDocumentDisplayHref
    }
  }, [_c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: _defineProperty({}, "".concat(_vm.EntityType.DOCUMENT, "--text"), true)
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.computedTrackingDocumentIcon,
      "icon-color": "var(--v-documents-base)",
      "icon-size": "14px"
    }
  }), _c('strong', [_vm._v(_vm._s(_vm.computedTrackingDocumentDisplay))])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.shouldDisplayHardwareListChip ? _c('div', {
    staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state",
    attrs: {
      "tooltip": "This is associated with a Hardware List."
    }
  }, [_vm.computedHardwareListDisplayHref ? _c('router-link', {
    attrs: {
      "to": _vm.computedHardwareListDisplayHref
    }
  }, [_c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: _defineProperty({}, "".concat(_vm.EntityType.HARDWARE_LIST, "--text"), true)
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.HARDWARE_LIST],
      "icon-size": "14px"
    }
  }), _vm.localEntity && _vm.localEntity.hardwareList && _vm.localEntity.hardwareList.name ? _c('div', [_c('strong', [_vm._v(_vm._s(_vm.localEntity.hardwareList.name) + " (")]), _vm.localEntity && _vm.localEntity.hardwareList ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.localEntity.hardwareList.number))]) : _vm._e(), _c('strong', [_vm._v(")")])]) : _vm.localEntity && _vm.localEntity.hardwareList && _vm.localEntity.hardwareList.number ? _c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.localEntity.hardwareList.number) + " ")]) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "assembly-template-parts",
    attrs: {
      "title": _vm.assemblyTemplatePartsTitle
    }
  }, [_vm.shouldDisplayDrawingNumberChip ? _c('div', {
    staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state"
  }, [_c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: {
      'assembly_template-color': !_vm.isEventAssemblyTemplate,
      'events--text': _vm.isEventAssemblyTemplate,
      highlight: _vm.shouldHighlightConnection
    }
  }, [_c('c-icon-fa', {
    staticClass: "pr-1",
    attrs: {
      "icon": _vm.iconForAssemblyTemplatePartSingle,
      "icon-size": "16px",
      "icon-color": "var(--v-item_masters-base)"
    }
  }), _c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.singleAssemblyTemplatePartDisplay))])], 1)], 1) : _vm._e(), _vm.shouldDisplayDescriptionChip && _vm.assemblyTemplatePartDescription ? _c('div', {
    staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state"
  }, [_c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: {
      'assembly_template-color': !_vm.isEventAssemblyTemplate,
      'events--text': _vm.isEventAssemblyTemplate,
      highlight: _vm.shouldHighlightConnection
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.assemblyTemplatePartDescription))])])], 1) : _vm._e(), _vm.shouldDisplaySideChip && _vm.assemblyTemplatePartSide ? _c('div', {
    staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state"
  }, [_c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: {
      'assembly_template-color': !_vm.isEventAssemblyTemplate,
      'events--text': _vm.isEventAssemblyTemplate,
      highlight: _vm.shouldHighlightConnection
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.assemblyTemplatePartSide))])])], 1) : _vm._e(), _vm.shouldDisplaySerialChip ? _c('div', {
    staticClass: "mini_chip__container_hack_for_vuetify_v_chip_hover_state"
  }, [_c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: {
      'assembly_template-color': !_vm.isEventAssemblyTemplate,
      'events--text': _vm.isEventAssemblyTemplate,
      highlight: _vm.shouldHighlightConnection
    }
  }, [_c('span', [_vm._v("Serial:")]), _c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.assemblyTemplatePartSerial))])])], 1) : _vm._e(), _vm.myAssemblyTemplateParts.length > 1 ? _c('v-chip', {
    staticClass: "mini_chip text-truncate",
    class: {
      'assembly_template-color': !_vm.isEventAssemblyTemplate,
      'events--text': _vm.isEventAssemblyTemplate,
      highlight: _vm.shouldHighlightConnection
    },
    attrs: {
      "disabled": ""
    }
  }, [_vm.iconForAssemblyTemplatePartMultiple ? _c('c-icon-fa', {
    staticClass: "pr-1",
    attrs: {
      "icon": _vm.iconForAssemblyTemplatePartMultiple,
      "icon-size": "14px"
    }
  }) : _vm._e(), _c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.myAssemblyTemplateParts.length) + " selected")])], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mini_actions_v2"
  }, [_vm.hasChildren ? _c('ButtonMiniAction', {
    attrs: {
      "disabled": !_vm.hasChildren,
      "icon": _vm.chevronIcon,
      "tooltip": !_vm.hasChildren ? 'No children' : _vm.localShowChildren ? 'Hide children' : 'Show children',
      "icon-size": "18px",
      "icon-color": "var(--cosmic-text)",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfShowChildren.apply(null, arguments);
      }
    }
  }) : _vm._e(), _vm.inlineClear && !_vm.readOnly ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.$icons.clear,
      "label": "Clear selected assembly template",
      "tooltip": "Clear selected assembly template",
      "tooltip-position": "left"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e(), _c('div', [_c('v-menu', {
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('ButtonMiniActionEllipsis', _vm._g({
          attrs: {
            "icon-color": "var(--cosmic-text)"
          }
        }, on))];
      }
    }], null, false, 1909160403)
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-tile', {
    attrs: {
      "tooltip": "Toggle details about this template slot.",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfShowDetail
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.info_strong,
      "icon-color": "var(--v-info-base)"
    }
  })], 1), _vm.showDetail ? _c('v-list-tile-title', [_vm._v("Hide detail")]) : _c('v-list-tile-title', [_vm._v("Show detail")])], 1), _c('v-divider'), _vm.localEntity && _vm.$route.params.id !== _vm.computedId ? _c('v-list-tile', {
    attrs: {
      "to": _vm.computedManageLink
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.MANAGE_PAGE,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Manage Template")])], 1) : _vm._e(), _vm.allowEdit ? _c('v-list-tile', {
    attrs: {
      "tooltip": _vm.isEventAssemblyTemplate ? 'Edit Event Assembly' : 'Edit Assembly Template details',
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfEdit
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.edit,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Edit Template details")])], 1) : _vm._e(), _c('v-divider'), _vm.allowChildCreation ? _c('v-list-tile', {
    attrs: {
      "tooltip": "Add child node, making this its parent.",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfCreateAssemblyTemplate
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.create_fat,
      "icon-color": "var(--v-success-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Add a child Template")])], 1) : _vm._e(), _vm.allowChildCreation ? _c('v-divider') : _vm._e(), _vm.allowEdit ? _c('v-list-tile', {
    attrs: {
      "tooltip": "Clone this Assembly Template into a new one.",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfCloneAssemblyTemplate
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.clone,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Clone into new Template")])], 1) : _vm._e(), _vm.localEntity && _vm.localEntity.sequence === 0 && !_vm.isPbsAssemblyTemplate && !_vm.isRootMegaAssemblyTemplate && !_vm.isChildMegaAssemblyTemplate && false ? _c('v-list-tile', {
    attrs: {
      "tooltip": "Copy from Inventory",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfCloneAssemblyTemplate
    }
  }, [_c('v-list-tile-action', [_c('c-fa-icon', {
    attrs: {
      "icon": _vm.$icons.clone,
      "icon-color": "var(--v-primary-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Copy from Inventory")])], 1) : _vm._e(), _vm.allowInstall ? _c('v-list-tile', {
    attrs: {
      "tooltip": "If this slot lists valid parts, select from those parts for installation.",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfInstall
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.install,
      "icon-color": "var(--v-success-base)"
    }
  })], 1), _c('v-list-tile-title', [_vm._v("Install Assembly into this slot")])], 1) : _vm._e(), _c('v-divider'), _c('v-list-tile', {
    attrs: {
      "tooltip": "Permanently delete this Assembly Template and its children",
      "tooltip-position": "left"
    },
    on: {
      "click": _vm.onClickOfDelete
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.trash,
      "icon-color": "var(--v-error-base)"
    }
  })], 1), _c('v-list-tile-title', {
    staticClass: "error--text"
  }, [_vm._v("Delete Template")])], 1)], 1)], 1)], 1)], 1)]) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.entityType,
      "center": "",
      "type": "linear"
    }
  }) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showDetail ? _c('div', {
    staticClass: "pa-2",
    staticStyle: {
      "background-color": "white",
      "border": "2px solid",
      "border-top": "0"
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "text-xs-right": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.showDetail = false;
      }
    }
  }, [_vm._v("Hide details")])], 1)], 1), _vm.isRoot ? _c('Alert', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons.root
          }
        })];
      },
      proxy: true
    }], null, false, 1866325887)
  }, [_c('span', [_vm._v(" This is known as a "), _c('u', [_vm._v("\"Root node\"")]), _vm._v(". ")]), _c('ul', [_c('li', [_vm._v("Root nodes do not have a parent node.")]), _c('li', [_vm._v("Root nodes have a sequence of "), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("0")]), _vm._v(".")]), !_vm.isEventAssemblyTemplate && !_vm.isRootMegaAssemblyTemplate && !_vm.isChildMegaAssemblyTemplate ? _c('li', [_vm._v(" Assign Hardware at this level to create “AsDesigned” template linkage. ")]) : _vm._e()])]) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "nowrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Name"
    }
  }, [_vm._v(_vm._s(_vm.name))])], 1), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Type"
    }
  }, [_vm._v(_vm._s(_vm.subTypeDisplay))])], 1), _vm.shouldShowMinMaxFields ? _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Min/max qty"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.maxQuantityDisplay))]), _vm._v("/ "), _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.minQuantityDisplay))])])], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Installed on"
    }
  }, [_vm._v(" " + _vm._s(_vm.installedOnDisplay) + " ")])], 1)], 1), _vm.hasDocumentAttached ? _c('v-layout', {
    staticClass: "mb-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('MiniDocument', {
    attrs: {
      "id": _vm.documentId,
      "title": "Attached Document"
    }
  })], 1)], 1) : _vm._e(), _vm.hasHardwareListAttached ? _c('v-layout', {
    staticClass: "mb-2",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('MiniHardwareList', {
    attrs: {
      "allow-child-creation": _vm.shouldAllowHardwareListUpdates,
      "allow-edit": _vm.shouldAllowHardwareListUpdates,
      "id": _vm.hardwareListId,
      "hide-item-type": "",
      "show-children": "",
      "title": "Attached Hardware List"
    }
  })], 1)], 1) : _vm._e(), !_vm.isRootMegaAssemblyTemplate && !_vm.isChildMegaAssemblyTemplate ? _c('AppTableActions', {
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "headers": _vm.AssemblyTemplatePartTableHeaders,
      "hide-pagination": _vm.assemblyTemplatePartTableItems.length < 5,
      "items": _vm.assemblyTemplatePartTableItems,
      "rows-per-page-items": _vm.assemblyTemplateRowsPerPageItems,
      "show-header": false
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": _vm.buildHardwareUrl(item)
          }
        })];
      }
    }], null, false, 1439552368)
  }) : _vm._e()], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowChildContainer ? _c('div', {
    staticClass: "mini_child_container",
    class: _defineProperty(_defineProperty(_defineProperty({}, "".concat(_vm.EntityType.ASSEMBLY_TEMPLATE, " ").concat(_vm.EntityType.ASSEMBLY_TEMPLATE, "--text"), _vm.isGenericAssemblyTemplate), "event_assemblies ".concat(_vm.EntityType.EVENT, "--text"), _vm.isEventAssemblyTemplate), "".concat(_vm.EntityType.PRODUCT_BREAKDOWN_STRUCTURE), _vm.isPbsAssemblyTemplate)
  }, _vm._l(_vm.myChildren, function (childAssemblyTemplate) {
    return _c('MiniAssemblyTemplate', {
      key: childAssemblyTemplate.id,
      staticClass: "ml-4 mini_child",
      attrs: {
        "allow-child-creation": _vm.allowChildCreation,
        "allow-edit": _vm.allowEdit,
        "clearable-id": _vm.clearableId,
        "entity": childAssemblyTemplate,
        "highlight-connection": _vm.highlightConnection,
        "highlight-id": _vm.highlightId,
        "inline-clear": _vm.clearableId === childAssemblyTemplate.id,
        "show-min-max": _vm.showMinMax,
        "show-children": ""
      }
    });
  }), 1) : _vm._e()]), _vm.showModalAssemblyTemplateEdit ? _c('ModalAssemblyTemplateEdit', {
    key: _vm.computedId,
    attrs: {
      "assembly-template-id": _vm.computedId,
      "parent-assembly-template-id": _vm.parentAssemblyTemplateId
    },
    on: {
      "assembly-template-deleted": _vm.emitAssemblyTemplateDeleted,
      "modal-close": _vm.onCloseOfModalAssemblyTemplateEdit,
      "refresh-parent": _vm.onRefreshParent,
      "refresh-self": _vm.onRefreshSelf
    }
  }) : _vm._e(), _vm.showModalAssemblyTemplateCreate ? _c('ModalAssemblyTemplateCreate', {
    key: _vm.computedId,
    attrs: {
      "parent-assembly-template-id": _vm.computedId
    },
    on: {
      "modal-close": _vm.onCloseOfModalAssemblyTemplateCreate,
      "refresh-self": _vm.onRefreshSelf
    }
  }) : _vm._e(), _vm.showModalAssemblyTemplateClone ? _c('ModalAssemblyTemplateClone', {
    key: _vm.computedId,
    attrs: {
      "assembly-template-id": _vm.computedId,
      "parent-assembly-template-id": _vm.parentAssemblyTemplateId
    },
    on: {
      "cloned-assembly-template": _vm.onAssemblyTemplateCloned,
      "modal-close": function modalClose($event) {
        _vm.showModalAssemblyTemplateClone = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };