function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, _vm.color, _vm.color), "hasNoActions", !_vm.hasAnyActions), "hasNoLineItems", !_vm.hasLineItems), "usesLineItems", _vm.usesLineItems), 'no-entity-icon', !_vm.showEntityTypeIcon)
  }, [_vm.showEntityTypeIcon ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.iconClass,
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _vm._e(), _c('v-layout', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1",
    attrs: {
      "title": "Document number"
    }
  }, [_vm._v(_vm._s(_vm.number))]), _c('p', {
    staticClass: "mt-1 mb-0"
  }, [_vm._v(_vm._s(_vm.name))])]), _c('div', {
    staticClass: "vertical-display-items hidden-xs-only white--text"
  }, [_c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Type")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value"
  }, [_vm._v(_vm._s(_vm.subTypeDisplay))])]), _c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Created by")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value"
  }, [_vm._v(_vm._s(_vm.createdByDisplay))])]), _c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Initiator")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value"
  }, [_vm._v(_vm._s(_vm.initiatorDisplay))])]), _vm.isClosed ? _c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Closed")]), _c('div', {
    staticClass: "text-truncate vertical-display-item-value"
  }, [_vm._v(_vm._s(_vm.closeDateDisplay))])]) : _vm._e(), !_vm.isClosed && _vm.usesLineItems ? _c('div', {
    staticClass: "vertical-display-item text-truncate"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', {
          staticClass: "text-capitalize mb-1"
        }, [_vm._v("Status")]), _c('div', _vm._g({
          staticClass: "text-truncate vertical-display-item-value"
        }, on), [_c('c-icon-fa', {
          attrs: {
            "icon": _vm.$icons['status_open']
          }
        })], 1)];
      }
    }], null, false, 1962211492)
  }, [_c('span', [_vm._v("Document is OPEN")])])], 1) : _vm._e(), !_vm.hasLineItems && _vm.usesLineItems ? _c('div', {
    staticClass: "vertical-display-item"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('c-icon-fa', _vm._g({
          staticClass: "mr-2",
          attrs: {
            "icon": _vm.$icons.warning
          }
        }, on))];
      }
    }], null, false, 797187238)
  }, [_c('span', [_vm._v("This Document has no line items.")])])], 1) : _vm._e()]), _vm.hasAnyActions ? _c('div', {
    staticClass: "mini_actions"
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_vm.shouldShowManageLink ? _c('router-link', _vm._g({
          staticClass: "white--text",
          attrs: {
            "to": _vm.manageLink
          }
        }, on), [_c('c-icon-fa', {
          staticClass: "manage_icon",
          attrs: {
            "icon": _vm.manageIcon,
            "icon-color": "white"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 479555071)
  }, [_c('span', [_vm._v("Navigate to the " + _vm._s(_vm.currentSystemName) + " manage page.")])]), _vm.inlineClear && !_vm.readOnly ? _c('c-icon-fa', {
    staticClass: "inline_clear_icon",
    attrs: {
      "icon": _vm.inlineClearIcon,
      "icon-color": "white",
      "tooltip": "Clear selection"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "type": "linear",
      "center": ""
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };