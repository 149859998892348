var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    class: {
      app_item_drawing_display: true,
      is_initialized: _vm.shouldShowItemDrawingDisplay
    }
  }, [!_vm.shouldShowItemDrawingDisplay ? _c('AppLoader', {
    staticClass: "loader",
    attrs: {
      "color": _vm.EntityType.ITEM_DRAWING
    }
  }) : _vm._e(), !_vm.isFetching && !_vm.isMinimized && _vm.transformedSelectedEntity ? _c('div', {
    class: {
      minimized: _vm.isMinimized,
      is_initialized: _vm.shouldShowItemDrawingDisplay
    }
  }, [_c('div', {
    staticClass: "ahd--grid-container item_drawing_display_grid"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Drawing num:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.transformedSelectedEntity.drawingNumber))])])]), _vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--grid-item all_drawing_sizes"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Classes:")]), _vm.transformedSelectedEntity.itemClasses && _vm.transformedSelectedEntity.itemClasses.length ? _c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto",
      "width": "120px"
    }
  }, _vm._l(_vm.transformedSelectedEntity._itemClasses, function (itemClass) {
    return _c('li', {
      key: itemClass,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(itemClass) + " ")]);
  }), 0) : _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v("—")])]) : _vm._e(), _c('div', {
    staticClass: "ahd--grid-item all_drawing_sizes"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Sizes:")]), _vm.transformedSelectedEntity.sizes && _vm.transformedSelectedEntity.sizes.length ? _c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto",
      "width": "120px"
    }
  }, _vm._l(_vm.transformedSelectedEntity._sizes, function (size) {
    return _c('li', {
      key: size,
      staticClass: "pl-0 font-weight-bold monospace_font"
    }, [_vm._v(" " + _vm._s(size) + " ")]);
  }), 0) : _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v("—")])])]), _c('div', {
    staticClass: "ahd--column-two"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("PoC:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold",
    staticStyle: {
      "width": "max-content"
    }
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._pointOfContact) + " ")])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("MTBF:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._meanTimeBetweenFailure) + " ")])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("MTTR:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedSelectedEntity._meanTimeToRepair) + " ")])])]), _c('div', {
    staticClass: "ahd--column-three"
  }, [_vm.transformedSelectedEntity ? _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Aliases:")]), _vm.transformedSelectedEntity.aliases && _vm.transformedSelectedEntity.aliases.length ? _c('ul', {
    staticClass: "pa-0 list-style-none",
    staticStyle: {
      "max-height": "80px",
      "overflow-y": "auto",
      "width": "120px"
    }
  }, _vm._l(_vm.transformedSelectedEntity._aliases, function (alias) {
    return _c('li', {
      key: alias,
      staticClass: "pl-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(alias) + " ")]);
  }), 0) : _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_vm._v("—")])]) : _vm._e()]), _c('div', {
    staticClass: "ahd--column-five"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("EEE identifier:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('AppBooleanDisplay', {
    attrs: {
      "value": _vm.transformedSelectedEntity.eeeIdentifier,
      "icon-size": "16px"
    }
  })], 1)]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Initialization:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('c-icon-fa', {
    attrs: {
      "icon-size": "16px",
      "icon": _vm.transformedSelectedEntity._initializationStatusIconOnly,
      "icon-color": _vm.transformedSelectedEntity._initializationStatusIconColor,
      "tooltip": _vm.transformedSelectedEntity._initializationStatusIconTitle
    }
  })], 1)])]), _vm._m(0)]), _c('div', {
    staticClass: "ahd--solo-row ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Description:")]), _c('span', [_vm._v(_vm._s(_vm.transformedSelectedEntity._description))])]), _vm.isArchived ? _c('div', {
    staticClass: "ahd--solo-row mt-2"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('strong', {
    staticClass: "error--text lighten-2"
  }, [_vm._v("Archived")])])], 1) : _vm._e()]) : _vm._e(), !_vm.isFetching && _vm.isMinimized ? _c('div', [_c('MiniItemDrawing', {
    attrs: {
      "entity": _vm.transformedSelectedEntity,
      "show-manage-link": false
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "ahd--solo-row mt-2",
    class: {
      is_initialized: _vm.shouldShowItemDrawingDisplay
    }
  }, [_vm.transformedSelectedEntity ? _c('router-link', {
    staticClass: "adh_button_link",
    attrs: {
      "tooltip": "View Item drawing manage page",
      "tooltip-position": "right",
      "to": _vm.transformedSelectedEntity._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_DRAWING],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasItemMasters && _vm.transformedItemMasters.length > 1 ? _c('c-menu', {
    staticClass: "ml-2",
    attrs: {
      "align-left": "",
      "border-color": "var(--v-item_masters-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-item_masters-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
      "icon-size": "16px",
      "icon-color": "#fff"
    }
  }), _vm._v(" Item masters ")], 1), _vm._l(_vm.transformedItemMasters, function (im, index) {
    return _c('div', {
      key: index
    }, [im._urlManage ? _c('c-menu-item', {
      attrs: {
        "href": im._urlManage,
        "icon": _vm.$icons.MANAGE_PAGE,
        "icon-size": "16px",
        "icon-color": "var(--v-item_masters-base)"
      }
    }, [_c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(im._asBuiltNumber))]), im.side !== _vm.Side.NONE ? _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(_vm._s(im._side))]) : _vm._e()]) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.hasItemMasters && _vm.transformedItemMasters.length === 1 ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item master manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemMasters[0]._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasItemInstances && _vm.transformedItemInstances.length > 1 ? _c('c-menu', {
    staticClass: "ml-2",
    attrs: {
      "align-left": "",
      "border-color": "var(--v-item_instances-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-item_instances-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-size": "16px",
      "icon-color": "#fff"
    }
  }), _vm._v(" Item instances ")], 1), _vm._l(_vm.transformedItemInstances, function (ii, index) {
    return _c('div', {
      key: index
    }, [ii._urlManage ? _c('c-menu-item', {
      attrs: {
        "href": ii._urlManage,
        "icon": _vm.$icons.MANAGE_PAGE,
        "icon-size": "16px",
        "icon-color": "var(--v-item_instances-base)"
      }
    }, [ii.asBuiltNumber !== _vm.DB_DEFAULT ? _c('span', {
      staticClass: "monospace_font"
    }, [_c('small', [_vm._v("ab")]), _vm._v(_vm._s(ii._asBuiltNumber) + " ")]) : _vm._e(), ii.side !== _vm.Side.NONE ? _c('span', [_vm._v("—")]) : _vm._e(), ii.side !== _vm.Side.NONE ? _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(ii._side) + " ")]) : _vm._e(), ii.size && ii.size !== _vm.DB_DEFAULT ? _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v("[" + _vm._s(ii._size) + "] ")]) : _vm._e(), ii.serialNumber ? _c('span', {
      staticClass: "monospace_font"
    }, [_c('small', [_vm._v("sn")]), _vm._v(_vm._s(ii._serialNumber) + " ")]) : _vm._e(), ii.lotNumber ? _c('span', {
      staticClass: "monospace_font"
    }, [_c('small', [_vm._v("lot")]), _vm._v(_vm._s(ii._lotNumber))]) : _vm._e()]) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.hasItemInstances && _vm.transformedItemInstances.length === 1 ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View Item instance manage page",
      "tooltip-position": "right",
      "to": _vm.transformedItemInstances[0]._urlManage
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_INSTANCE],
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _vm.hasLinks ? _c('c-menu', {
    staticClass: "ml-2",
    attrs: {
      "align-left": "",
      "border-color": "var(--v-comments-base)",
      "border-size": "2px"
    }
  }, [_c('ButtonGeneric', {
    attrs: {
      "slot": "activator",
      "small": "",
      "icon-color": "white",
      "color": "var(--v-comments-base)"
    },
    slot: "activator"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons.link,
      "icon-size": "16px",
      "icon-color": "#fff"
    }
  }), _vm._v(" Links ")], 1), _vm._l(_vm.transformedLinks, function (link, index) {
    return _c('div', {
      key: index
    }, [link.currentVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.currentVersionUrl,
        "icon": _vm.$icons.link,
        "icon-size": "16px",
        "icon-color": "var(--v-comments-base)"
      }
    }, [_vm._v(" " + _vm._s(link.currentVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e(), link.workingVersionUrl ? _c('v-divider') : _vm._e(), link.workingVersionUrl ? _c('c-menu-item', {
      attrs: {
        "href": link.workingVersionUrl,
        "icon": _vm.$icons.link,
        "icon-size": "16px",
        "icon-color": "var(--v-comments-base)"
      }
    }, [_vm._v(" " + _vm._s(link.workingVersionUrl)), _c('br'), link.currentRevision ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" current rev: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._currentRevision) + " ")])]) : _vm._e(), link.referenceNumber ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v(" ref num: "), _c('span', {
      staticClass: "monospace_font"
    }, [_vm._v(" " + _vm._s(link._referenceNumber) + " ")])]) : _vm._e(), link.subType ? _c('small', {
      staticClass: "d-block"
    }, [_vm._v("type: " + _vm._s(link._subType))]) : _vm._e()]) : _vm._e()], 1);
  })], 2) : _vm._e(), _vm.transformedSelectedEntity && _vm.changelogHref ? _c('router-link', {
    staticClass: "adh_button_link ml-2",
    attrs: {
      "tooltip": "View the changelog for ".concat(_vm.transformedSelectedEntity.drawingNumber),
      "tooltip-position": "right",
      "to": _vm.changelogHref
    }
  }, [_c('c-icon-fa', {
    attrs: {
      "icon": _vm.$icons.history,
      "icon-color": "#fff",
      "icon-size": "16px"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center ml-2"
  }, [!_vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-down-left-and-arrow-up-right-to-center",
      "tooltip": "Minimize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMinimize
    }
  }) : _vm.isMinimized ? _c('c-button-icon', {
    attrs: {
      "icon-size": "16px",
      "icon": "fa-regular fa-arrow-up-right-and-arrow-down-left-from-center",
      "tooltip": "Maximize card",
      "tooltip-position": "right"
    },
    on: {
      "c-click": _vm.onClickOfMaximize
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--column-six"
  }, [_c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Avail qty:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("0")])])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Inst qty:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("0")])])]), _c('div', {
    staticClass: "ahd--grid-item"
  }, [_c('p', {
    staticClass: "ahd--label"
  }, [_vm._v("Qty:")]), _c('p', {
    staticClass: "pl-0 font-weight-bold"
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v("0")])])])]);
}];
export { render, staticRenderFns };