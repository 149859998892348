var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.computedColor,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          staticClass: "mb-2",
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "remove-margin-left": "",
            "remove-margin-bottom": "",
            "color": "info"
          }
        }, [_vm._v("Cloning")]), _c('MiniAssemblyTemplate', {
          staticClass: "mt-2",
          attrs: {
            "id": _vm.selectedAssemblyTemplateId,
            "show-children-initially": false,
            "show-children": ""
          }
        })], 1), _vm.assemblyTemplateId ? _c('v-flex', {
          attrs: {
            "md12": "",
            "lg6": "",
            "pr-2": ""
          }
        }, [_c('c-text', {
          attrs: {
            "autofocus": "",
            "label": "New name",
            "c-value": _vm.formData.name
          },
          on: {
            "c-input": function cInput($event) {
              _vm.formData.name = $event.detail;
            }
          }
        })], 1) : _vm._e(), false ? _c('v-flex', {
          attrs: {
            "md12": "",
            "lg6": "",
            "pr-2": ""
          }
        }, [_c('AppNumber', {
          attrs: {
            "error-messages": _vm.sequenceNumberErrorMessage,
            "messages": _vm.sequenceNumberMessage,
            "autofocus": "",
            "label": "Sequence",
            "required": ""
          },
          model: {
            value: _vm.formData.sequence,
            callback: function callback($$v) {
              _vm.$set(_vm.formData, "sequence", $$v);
            },
            expression: "formData.sequence"
          }
        })], 1) : _vm._e(), !_vm.selectedAssemblyTemplateId ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('SelectorAssemblyTemplate', {
          attrs: {
            "existing-values": _vm.existingValues,
            "filters": _vm.subTypeFilter,
            "locked-filters": ['subType']
          },
          on: {
            "assembly-template-selected": _vm.onAssemblyTemplateSelected
          }
        })], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCloneButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCloneAssemblyTemplate
          }
        }, [_vm._v(" Clone ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };