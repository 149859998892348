var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.computedColor,
      "hide-title-section": "",
      "modal-width": "95vw"
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.parentAssemblyTemplateId ? _c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateAssemblyTemplate
          }
        }, [_vm._v(" Create " + _vm._s(_vm.subTypeDisplay) + " ")]) : _c('ButtonCreate', {
          attrs: {
            "disabled": _vm.shouldDisableCreateButton,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfCreateAssemblyTemplate
          }
        }, [_vm._v(" Attach child ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "content"
  }, [!_vm.parentAssemblyTemplateId ? _c('Alert', {
    attrs: {
      "type": _vm.AlertType.ERROR
    }
  }, [_vm._v("No parent found. Please close this and try again.")]) : _vm._e(), _vm.parentAssemblyTemplateId ? _c('div', {
    staticClass: "mb-4"
  }, [_c('MiniAssemblyTemplate', {
    staticClass: "parent_mini",
    attrs: {
      "id": _vm.parentAssemblyTemplateId,
      "title": "Child will be attached to"
    }
  }), _c('v-divider', {
    staticClass: "my-2"
  })], 1) : _vm._e(), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "Name",
      "c-value": _vm.formData.name,
      "clearable": "",
      "tooltip": "This will be the display of the node and serve as it's alias",
      "tooltip-position": "top",
      "autocomplete": "off"
    },
    on: {
      "c-input": function cInput($event) {
        _vm.formData.name = $event.detail;
      }
    }
  })], 1), !_vm.isParentPbsAssemblyTemplate ? _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "enum": _vm.Side,
      "display": _vm.SideDisplay,
      "sort": false,
      "chips": false,
      "messages": _vm.installedOnMessage,
      "label": "Install on",
      "required": ""
    },
    model: {
      value: _vm.formData.installedOn,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "installedOn", $$v);
      },
      expression: "formData.installedOn"
    }
  })], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('c-text', {
    attrs: {
      "label": "PBS ID",
      "c-value": _vm.formData.pbsItemId,
      "clearable": "",
      "messages": "Unique identifier"
    },
    on: {
      "c-input": function cInput($event) {
        _vm.formData.pbsItemId = $event.detail;
      }
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "error-messages": _vm.sequenceNumberErrorMessage,
      "messages": _vm.sequenceNumberMessage,
      "autofocus": "",
      "label": "Sequence",
      "required": "",
      "tooltip-position": "right",
      "tooltip": "This is how you order this node with respect to it's siblings"
    },
    model: {
      value: _vm.formData.sequence,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sequence", $$v);
      },
      expression: "formData.sequence"
    }
  })], 1), !_vm.isParentPbsAssemblyTemplate ? _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-1": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Min qty",
      "max": _vm.formData.maxQuantity,
      "pre-computed-rules": [_vm.minIsLessThanMax]
    },
    model: {
      value: _vm.formData.minQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "minQuantity", $$v);
      },
      expression: "formData.minQuantity"
    }
  })], 1) : _vm._e(), !_vm.isParentPbsAssemblyTemplate ? _c('v-flex', {
    attrs: {
      "xs2": "",
      "pr-3": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max qty",
      "pre-computed-rules": [_vm.minIsLessThanMax]
    },
    model: {
      value: _vm.formData.maxQuantity,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "maxQuantity", $$v);
      },
      expression: "formData.maxQuantity"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('AppExpansionPanel', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.computedColor,
      "is-open": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm.isRoot && _vm.isParentPbsAssemblyTemplate ? _c('span', {
          staticClass: "title"
        }, [_vm._v("Hardware")]) : _vm._e(), _vm.isRoot && _vm.isParentGenericAssemblyTemplate ? _c('span', {
          staticClass: "title"
        }, [_vm._v("AsDesigned Templates")]) : _vm._e(), !_vm.isRoot && _vm.isParentGenericAssemblyTemplate ? _c('span', {
          staticClass: "title"
        }, [_vm._v("Hardware shopping list")]) : _vm._e(), !_vm.isRoot && !_vm.isParentGenericAssemblyTemplate ? _c('span', {
          staticClass: "title"
        }, [_vm._v("Hardware")]) : _vm._e(), _vm._v(" ( "), _c('span', {
          staticClass: "monospace_font"
        }, [_c('strong', [_vm._v(_vm._s(_vm.selectedAssemblyTemplateParts.length))])]), _vm._v(" ) ")];
      },
      proxy: true
    }])
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppTable', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "headers": _vm.AssemblyTemplateTreeTableHeaders,
      "hide-pagination": _vm.assemblyTemplatePartTableItems.length < 5,
      "items": _vm.assemblyTemplatePartTableItems,
      "rows-per-page-items": _vm.rowsPerPageItems,
      "show-header": false
    },
    on: {
      "rowClicked": _vm.onClickOfAssemblyTemplatePartTableItem
    }
  }), _vm.isParentEventAssemblyTemplate ? _c('div', [_c('SearchItemInstance', {
    attrs: {
      "keep-search-results-open": "",
      "keep-search-value-after-selection": "",
      "output-mode": "object"
    },
    on: {
      "input": _vm.onClickOfSearchResult
    }
  }), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "text-xs-center or_sep",
    attrs: {
      "xs12": "",
      "mb-3": ""
    }
  }, [_c('strong', [_c('pre', [_vm._v("- OR CREATE A TEMPORARY PLACEHOLDER -")])])])], 1), _c('v-layout', {
    attrs: {
      "row": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('AppSelectDrawingNumber', {
    attrs: {
      "clearable": "",
      "label": "Drawing number",
      "multiple": false
    },
    model: {
      value: _vm.ghostAssemblyTemplatePartToAdd.drawingNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.ghostAssemblyTemplatePartToAdd, "drawingNumber", $$v);
      },
      expression: "ghostAssemblyTemplatePartToAdd.drawingNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": "",
      "px-2": ""
    }
  }, [_c('AppSelectAsBuiltByDrawingNumber', {
    attrs: {
      "disabled": !_vm.ghostAssemblyTemplatePartToAdd.drawingNumber,
      "drawing-number": _vm.ghostAssemblyTemplatePartToAdd.drawingNumber,
      "clearable": ""
    },
    model: {
      value: _vm.ghostAssemblyTemplatePartToAddAsBuilt,
      callback: function callback($$v) {
        _vm.ghostAssemblyTemplatePartToAddAsBuilt = $$v;
      },
      expression: "ghostAssemblyTemplatePartToAddAsBuilt"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs4": ""
    }
  }, [_c('c-text', {
    attrs: {
      "autofocus": "",
      "label": "Serial",
      "c-value": _vm.ghostAssemblyTemplatePartToAdd.serialNumber
    },
    on: {
      "c-input": function cInput($event) {
        _vm.ghostAssemblyTemplatePartToAdd.serialNumber = $event.detail;
      }
    }
  })], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowAddPlaceholderButton ? _c('v-layout', {
    attrs: {
      "row": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('ButtonCreate', {
    attrs: {
      "disabled": _vm.shouldDisableAddGhostButton,
      "is-saving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfAddGhostAssemblyTemplatePart
    }
  }, [_vm._v("Add Placeholder")])], 1)], 1) : _vm._e()], 1)], 1) : _c('SearchItemDrawingItemMaster', {
    attrs: {
      "rows-per-page-items": _vm.rowsPerPageItems,
      "keep-search-results-open": "",
      "keep-search-value-after-selection": "",
      "output-mode": "object"
    },
    on: {
      "input": _vm.onClickOfSearchResult
    }
  })], 1)], 1)], 1)], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };